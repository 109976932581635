import firebase from "./firebase";

export function apiRequest(path, method = "GET", data) {
  if (!firebase.auth().currentUser) {
    return fetch(`/api/${path}`, {
      method: method,
      headers: {
        "Content-Type": "application/json"
      },
      body: data ? JSON.stringify(data) : undefined,
    })
    .then((response) => response.json());
  }
  else {
    return firebase
      .auth()
      .currentUser.getIdToken()
      .then((accessToken) => {
        return fetch(`/api/${path}`, {
          method: method,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: data ? JSON.stringify(data) : undefined,
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.status === "error") {
              // Automatically signout user if accessToken is no longer valid
              if (response.code === "auth/invalid-user-token") {
                firebase.auth().signOut();
              }

              throw new CustomError(response.code, response.message);
            } else {
              return response;
            }
          });
      });
  }
}

// Create an Error with custom message and code
export function CustomError(code, message) {
  const error = new Error(message);
  error.code = code;
  return error;
}

